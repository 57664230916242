import React, { useState, useEffect } from 'react';
import { VscChevronLeft, VscChevronRight } from 'react-icons/vsc';

const Datepicker = (props) => {

    const ROWS = 6;
    const COLUMNS = 7;

    const NBSP = '\u00a0';

    const daysOfWeek = [ 'H', 'K', 'Sze', 'Cs', 'P', 'Szo', 'V' ];
    const monthsOfYear = [
        { key: '01', value: 'január' },
        { key: '02', value: 'február' },
        { key: '03', value: 'március' },
        { key: '04', value: 'április' },
        { key: '05', value: 'május' },
        { key: '06', value: 'június' },
        { key: '07', value: 'július' },
        { key: '08', value: 'augusztus' },
        { key: '09', value: 'szeptember' },
        { key: '10', value: 'október' },
        { key: '11', value: 'november' },
        { key: '12', value: 'december' }
    ];

    const [state, setState] = useState({
        year : 1970,
        month : 0,
        day : undefined
    });
    const [days, setDays] = useState([]);

    const dateChanged = () => {
        const toZeroPrefixString = (value) => {
            return ((value<10) ? '0' : '') + value;
        };

        props.onDateChange({ 
            year : state.year,
            month : state.month + 1,
            day : state.day,
            date : `${state.year.toString()}-${toZeroPrefixString(state.month + 1)}-${toZeroPrefixString((state.day!==undefined) ? state.day : 1)}`   
        });
    };

    const setMonth = (year, month) => {
        const result = [];

        let dayIterator = 1;

        const date = new Date();
        date.setFullYear(year);
        date.setMonth(month);
        date.setDate(dayIterator);

        let firstDay = date.getDay() - 1;
        if(firstDay<0) {
            firstDay += 7;
        }

        for(let i=0; i<ROWS; i++) {
            const week = [];

            for(let j=0; j<COLUMNS; j++) { 
                const day = {
                    key : (i*ROWS) + j
                };

                date.setDate(dayIterator);

                if((i===0 && j<firstDay) || (date.getDate()<dayIterator)) {
                    day.value = NBSP;

                    date.setMonth(month);
                } else {
                    day.value = (dayIterator++).toString();
                }
                   
                week.push(day);
            }

            result.push(week);
        }

        setDays(result);

        dateChanged();
    };

    const changeMonth = (direction) => {
        let year = state.year;
        let month = state.month + direction;

        if(month<0) {
            year -= 1;
            month = 11;
        } else if(month>11) {
            year += 1;
            month = 0;
        }

        setState({
            year : year,
            month : month
        });

        dateChanged();
    };

    const selectDate = (e) => {
        const day = e.target.innerHTML;

        if(day!==NBSP) {
            setState({
                year : state.year,
                month : state.month,
                day : parseInt(day)
            });

            dateChanged();
        }
    };

    useEffect(() => {
        if(days.length===0) {
            const now = new Date();

            setState({
                year : now.getFullYear(),
                month : now.getMonth()
            });

            setMonth(now.getFullYear(), now.getMonth());
        }
    }, [days]);

    useEffect(() => {
        setMonth(state.year, state.month);
    }, [state]);

    return (
        <div className="datepicker-container">
            <div className="row datepicker-month-row datepicker-header">
                <div className="col-10 datepicker-month-col">{`${state.year}. ${(monthsOfYear[state.month]!==undefined) ? monthsOfYear[state.month].value : ''}`}</div>
                <div className="col-1 datepicker-month-col datepicker-pointer" onClick={() => changeMonth(-1)}>
                    <VscChevronLeft />
                </div>
                <div className="col-1 datepicker-month-col datepicker-pointer" onClick={() => changeMonth(1)}>
                    <VscChevronRight />
                </div>
            </div>
            <div className="row row-cols-7 datepicker-row">
                {daysOfWeek.map((day, index) => {
                    return <div className="col datepicker-col datepicker-header" key={index}>{day}</div>
                })}
            </div>
            {days.map((week, index) => {
                return <div className="row row-cols-7 datepicker-row" key={index}>
                    {week.map(day => {
                        return <div className={`col datepicker-col ${(day.value!==NBSP) ? 'datepicker-pointer' : ''} ${(state.day!==undefined && state.day.toString()===day.value) ? 'datepicker-selected' : ''}`} key={day.key} onClick={(e) => selectDate(e)}>{day.value}</div>
                    })}
                </div>
            })}
        </div>
    );
};

export default Datepicker;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {

    const navigate = useNavigate();

    const [state, setState] = useState({
        username: '',
        password: ''
    });
    const [waitingForFetch, setWaitingForFetch] = useState(false);

    const handleUsername = (e) => {
        setState(Object.assign(state, { username : e.target.value }));
    }

    const handlePassword = (e) => {
        setState(Object.assign(state, { password : e.target.value }));
    }

    const login = (e) => {
        e.preventDefault();

        setWaitingForFetch(true);

        try {
            fetch(process.env.REACT_APP_API_URL + '/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(state)
            }).then(res => res.json()).then(data => {
                if(data.success) {
                    sessionStorage.setItem(process.env.REACT_APP_TOKEN_STORAGE_KEY, data.token);

                    setWaitingForFetch(false);

                    navigate('/daily-stats');
                } else {
                    setWaitingForFetch(false);
                }
            });
        } catch(cause) {
            setWaitingForFetch(false);

            console.log(cause);
        }
    };

    return (
        <form >
            <div className="form-group">
                <label>Felhasználónév</label>
                <input type="email" className="form-control" disabled={waitingForFetch} onChange={handleUsername} />
            </div>
            <div className="form-group">
                <label>Jelszó</label>
                <input type="password" className="form-control" disabled={waitingForFetch} onChange={handlePassword} />
            </div>
            <button type="submit" className="btn btn-block" disabled={waitingForFetch} onClick={login}>
                <span className={`spinner-border spinner-border-sm ${!waitingForFetch ? 'visually-hidden' : ''}`} role="status" aria-hidden="true"></span>
                <span className={`btn-content ${waitingForFetch ? 'visually-hidden' : ''}`}>Bejelentkezés</span>
            </button>
        </form>
    );
};

export default Login;

import './app.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router,  Routes, Route } from 'react-router-dom';

import Login from './components/login.component';
import DailyStats from './components/daily-stats.component';

require('dotenv').config();

function App() {
	return (
		<Router>
			<div className="wrapper">
        		<div className="inner">
					<Routes>
						<Route path="/" element={<Login />} />
						<Route path="/daily-stats" element={<DailyStats />} />
					</Routes>
				</div>
			</div>
	  	</Router>
	);
}

export default App;

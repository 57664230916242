import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import Datepicker from './datepicker.component';

const DailyStats = () => {

    const formats = [ 'xlsx', 'csv', 'json' ];

    let downloadReference = null;

    const [state, setState] = useState({
        from : null,
        to : null,
        video : null,
        format : formats[0]
    });
    const [download, setDownload] = useState({
        filename: null,
        url: null
    }); 
    const [waitingForFetch, setWaitingForFetch] = useState(false);
    const [videos, setVideos] = useState([]); 
    const [selectingDay, setSelectingDay] = useState(false);

    const accessToken = () => {
        const localToken = localStorage[process.env.REACT_APP_TOKEN_STORAGE_KEY];
        const sessionToken = sessionStorage[process.env.REACT_APP_TOKEN_STORAGE_KEY];

        return (localToken!==undefined) ? localToken : ((sessionToken!==undefined) ? sessionToken : undefined);
    }

    const getVideos = () => {
        try {
            fetch(process.env.REACT_APP_API_URL + '/videos', {
                method: 'GET',
                headers: {
                    'Authorization' : accessToken()
                }
            }).then(res => res.json()).then(data => {
                if(data.success) {
                    setVideos(data.videos);
                }
            });
        } catch(cause) {
            console.log(cause);
        }
    };

    const setFrom = (e) => {
        setState(Object.assign(state, { from : e.date }));

        if(e.day===undefined) {
            setSelectingDay(false);

            setState(Object.assign(state, { to : `${e.year}-${(e.month<10) ? '0' : ''}${e.month}-31` }));
        } else {
            setSelectingDay(true);
        }

        console.log(state);
    };

    const setTo = (e) => {
        setState(Object.assign(state, { to : e.date}));
    };

    const setVideo = (e) => {
        setState(Object.assign(state, { video : e.target.value }));
    };

    const setFormat = (e) => {
        setState(Object.assign(state, { format : e.target.value }));
    };

    const downloadStats = (e) => {
        e.preventDefault();

        setWaitingForFetch(true);

        if(state.video===null && videos.length>0) {
            setState(Object.assign(state, { video : videos[0].id }));
        } 

        const query = `?video=${state.video}&from=${state.from}&to=${state.to}&format=${state.format}`;

        try {
            fetch(process.env.REACT_APP_API_URL + '/daily-stats' + query, {
                method: 'GET',
                headers: {
                    'Authorization' : accessToken()
                }
            }).then(res => (state.format==='xlsx') ? res.arrayBuffer() : res.text()).then(data => {
                const video = videos.filter(video => video.id===state.video);
                
                const filename = `${(video.length>0) ? video[0].name : state.video}_${state.from}-${state.to}.${state.format}`;

                const blob = new Blob([data]);
                const url = URL.createObjectURL(blob);
                
                setDownload({ 
                    filename : filename,
                    url : url
                });
            });
        } catch(cause) {
            setWaitingForFetch(false);
        }
    };

    useEffect(() => {
        if(videos.length===0) {
            getVideos();
        }
    }, [videos])

    useEffect(() => {
        if(download.filename!==null && download.url!==null) {
            downloadReference.click();

            setWaitingForFetch(false);
        }
    }, [download, downloadReference] );

    if(accessToken()===undefined) {  
        return <Navigate to='/' />;
    }

    return ( 
        <form>   
            <div className="container">
                <label>Időszak</label>
                <div className="row">
                    <div className="col">
                        <Datepicker onDateChange={setFrom}/>
                    </div>
                    <div className="col">
                        <div className={(!selectingDay) ? 'visually-hidden' : ''}>
                            <Datepicker onDateChange={setTo}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group">
                        <label>Videó</label>
                        <select className="form-select" disabled={waitingForFetch} onChange={setVideo}>
                            {videos.map(video => {
                                return <option key={video.id} value={video.id}>{video.name}</option>;
                            })}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="form-group">
                        <label>Formátum</label>
                        <div className="row">
                            {formats.map(format => {
                                return (
                                    <div className="col" key={format}>
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="radioOptions" id={format} value={format} defaultChecked={format===state.format} onClick={setFormat} disabled={waitingForFetch}/>
                                            <label className="form-check-label">{format}</label>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <button type="submit" className="btn btn-block" disabled={waitingForFetch} onClick={downloadStats}>
                        <span className={`spinner-border spinner-border-sm ${(!waitingForFetch) ? 'visually-hidden' : ''}`} role="status" aria-hidden="true"></span>
                        <span className={`btn-content ${(waitingForFetch) ? 'visually-hidden' : ''}`}>Letöltés</span>
                    </button>
                    <a className="visually-hidden" download={download.filename} href={download.url}  ref={(e) => downloadReference = e}></a>
                </div>
            </div>
        </form>
    );
};

export default DailyStats;